import { isFeatureEnabled } from 'src/config/features-toggle';
import type { FooterSegment, FooterSegmentCompact } from 'src/config/footer';
import { getProductConfig } from 'src/config/product';

const whoWeAreUrl = getProductConfig('whoWeAreUrl');
const leaderUrl = getProductConfig('leaderUrl');
const pressOfficeUrl = getProductConfig('pressOfficeUrl');
const advertisingUrl = getProductConfig('advertisingUrl');
const contactUsUrl = getProductConfig('contactUsUrl');
const cityGuideUrl = getProductConfig('cityGuideUrl');
const insightsUrl = getProductConfig('insightsUrl');
const workWithUsUrl = getProductConfig('workWithUsUrl');
const userAssistance = getProductConfig('userAssistance');
const sellerLeadUrl = getProductConfig('sellerLeadUrl');
const evaluateBuildingUrl = getProductConfig('evaluateBuildingUrl');
const myHomeUrl = getProductConfig('myHomeUrl');
const publishedAdsLandingUrl = getProductConfig('publishedAdsLandingUrl');
const secretPropertiesUrl = getProductConfig('secretPropertiesUrl');
const mortgageUrl = getProductConfig('mortgageUrl');
const euroansaUrl = getProductConfig('euroansaUrl');
const antiScamUrl = getProductConfig('antiScamUrl');
const publishAdUrl = getProductConfig('publishAdUrl');
const networkGroupUrl = getProductConfig('networkGroupUrl');
const internationalLuxuryUrl = getProductConfig('internationalLuxuryUrl');
const immoSoftwareUrl = getProductConfig('immoSoftwareUrl');
const racSoftwareUrl = getProductConfig('racSoftwareUrl');
const virtualTourUrl = getProductConfig('virtualTourUrl');
const immoVisitaUrl = getProductConfig('immoVisitaUrl');
const professionalReportUrl = getProductConfig('professionalReportUrl');
const academyUrl = getProductConfig('academyUrl');
const newsUrl = getProductConfig('newsUrl');

//configuration for top of footer in big version (homepage)
const segments: FooterSegment[][] = [
  [
    {
      title: getProductConfig('brandName'),
      name: 'info',
      icon: 'info-circle',
      links: [
        ...(whoWeAreUrl
          ? [
              {
                label: 'ftr_anchor_who_we_are', // i18n-extract-keys ["ftr_anchor_who_we_are"]
                url: whoWeAreUrl,
                alternate: true,
              },
            ]
          : []),
        ...(leaderUrl
          ? [
              {
                label: 'ftr_anchor_nr_1', // i18n-extract-keys ["ftr_anchor_nr_1"]
                url: leaderUrl,
                alternate: true,
              },
            ]
          : []),
        ...(pressOfficeUrl
          ? [
              {
                label: 'ftr_anchor_press_office', // i18n-extract-keys ["ftr_anchor_press_office"]
                url: pressOfficeUrl,
                alternate: false,
              },
            ]
          : []),
        ...(advertisingUrl
          ? [
              {
                label: 'ftr_anchor_advertising', // i18n-extract-keys ["ftr_anchor_advertising"]
                url: advertisingUrl,
                alternate: false,
              },
            ]
          : []),
        ...(contactUsUrl
          ? [
              {
                label: 'ftr_anchor_customer_care_contacts', // i18n-extract-keys ["ftr_anchor_customer_care_contacts"]
                url: contactUsUrl,
                alternate: true,
              },
            ]
          : []),
        {
          label: 'db_category.id_5', // i18n-extract-keys ["db_category.id_5"]
          url: 'https://international.immobiliare.it/',
          target: '_blank',
          isClientOnlyLink: true,
          alternate: false,
        },
      ],
    },
    {
      title: 'ftr_title_market_analysis', // i18n-extract-keys ["ftr_title_market_analysis"]
      name: 'dataAndAnalisys',
      icon: 'chart',
      links: [
        ...(insightsUrl
          ? [
              {
                label: 'ftr_anchor_immo_insights', // i18n-extract-keys ["ftr_anchor_immo_insights"]
                url: `${insightsUrl}?from=footer`,
                alternate: false,
              },
            ]
          : []),
        ...(cityGuideUrl
          ? [
              {
                label: 'ftr_anchor_house_prices', // i18n-extract-keys ["ftr_anchor_house_prices"]
                url: cityGuideUrl,
                alternate: false,
                isClientSideNavigation: true,
              },
            ]
          : []),
      ],
    },
    {
      title: 'ftr_title_work_with_us', // i18n-extract-keys ["ftr_title_work_with_us"]
      name: 'workWithUs',
      icon: 'user-group',
      links: [
        ...(workWithUsUrl
          ? [
              {
                label: 'ftr_anchor_open_positions', // i18n-extract-keys ["ftr_anchor_open_positions"]
                url: workWithUsUrl,
                alternate: true,
              },
            ]
          : []),
      ],
    },
  ],
  [
    {
      title: 'ftr_title_privates', // i18n-extract-keys ["ftr_title_privates"]
      name: 'privates',
      icon: 'user-round',
      links: [
        ...(userAssistance
          ? [
              {
                label: 'ftr_anchor_faq', // i18n-extract-keys ["ftr_anchor_faq"]
                url: userAssistance,
                rel: 'nofollow noopener',
                alternate: true,
                target: '_blank' as const,
              },
            ]
          : []),
        ...(sellerLeadUrl
          ? [
              {
                label: 'lbl_sell_house', // i18n-extract-keys ["lbl_sell_house"]
                url: `${sellerLeadUrl}?from=footer`,
                alternate: false,
              },
            ]
          : []),
        ...(evaluateBuildingUrl
          ? [
              {
                label: 'lbl_appraise_property', // i18n-extract-keys ["lbl_appraise_property"]
                url: `${evaluateBuildingUrl}?from=footer`,
                alternate: true,
              },
            ]
          : []),
        ...(myHomeUrl
          ? [
              {
                label: 'mh.main_label', // i18n-extract-keys ["mh.main_label"]
                url: `${myHomeUrl}?from=footer`,
                alternate: false,
                isEnabled: isFeatureEnabled('MY_HOME'),
              },
            ]
          : []),
        ...(publishedAdsLandingUrl
          ? [
              {
                label: 'act_publish_a_ad', // i18n-extract-keys ["act_publish_a_ad"]
                url: `${publishedAdsLandingUrl}?from=footer`,
                avoidLocalizedUrl: true,
                alternate: true,
                rel: 'nofollow',
              },
            ]
          : []),
        ...(cityGuideUrl
          ? [
              {
                label: 'ftr_anchor_house_prices', // i18n-extract-keys ["ftr_anchor_house_prices"]
                url: cityGuideUrl,
                onlyAlternate: true,
                isClientSideNavigation: true,
              },
            ]
          : []),
        ...(secretPropertiesUrl
          ? [
              {
                label: 'lbl_secret_properties', // i18n-extract-keys ["lbl_secret_properties"]
                url: secretPropertiesUrl,
                alternate: true,
              },
            ]
          : []),
      ],
    },
    {
      title: 'ftr_slim_mortgages', // i18n-extract-keys ["ftr_slim_mortgages"]
      name: 'mortgage',
      icon: 'calculator',
      links: [
        ...(mortgageUrl
          ? [
              {
                label: 'ftr_anchor_mortgage_online', // i18n-extract-keys ["ftr_anchor_mortgage_online"]
                url: mortgageUrl,
                alternate: false,
                rel: 'nofollow',
              },
            ]
          : []),
        ...(euroansaUrl
          ? [
              {
                label: 'ftr_anchor_mortgage_offline', // i18n-extract-keys ["ftr_anchor_mortgage_offline"]
                url: euroansaUrl,
                alternate: false,
                rel: 'nofollow',
              },
            ]
          : []),
      ],
    },
    {
      title: 'ftr_title_safety', // i18n-extract-keys ["ftr_title_safety"]
      name: 'security',
      icon: 'shield',
      links: [
        ...(antiScamUrl
          ? [
              {
                label: 'ftr_anchor_anti_scams_advice', // i18n-extract-keys ["ftr_anchor_anti_scams_advice"]
                url: antiScamUrl,
                alternate: false,
                rel: 'nofollow',
              },
            ]
          : []),
      ],
    },
  ],
  [
    {
      title: 'ftr_title_agencies', // i18n-extract-keys ["ftr_title_agencies"]
      name: 'agencies',
      icon: 'suitcase',
      links: [
        ...(publishAdUrl
          ? [
              {
                label: 'ftr_anchor_publish_ad', // i18n-extract-keys ["ftr_anchor_publish_ad"]
                url: publishAdUrl,
                alternate: true,
              },
            ]
          : []),
        ...(networkGroupUrl
          ? [
              {
                label: 'ftr_our_portal', // i18n-extract-keys ["ftr_our_portal"]
                url: networkGroupUrl,
                alternate: true,
              },
            ]
          : []),
        ...(internationalLuxuryUrl
          ? [
              {
                label: 'LuxuryEstate',
                url: internationalLuxuryUrl,
                alternate: true,
                title: 'ftr_luxury_re', // i18n-extract-keys ["ftr_luxury_re"]
              },
            ]
          : []),
        ...(immoSoftwareUrl
          ? [
              {
                label: 'Getrix',
                url: immoSoftwareUrl,
                alternate: true,
                title: 'ftr_anchor_re_sw_gest', // i18n-extract-keys ["ftr_anchor_re_sw_gest"]
              },
            ]
          : []),
        ...(racSoftwareUrl
          ? [
              {
                label: 'ftr_rac_software', // i18n-extract-keys ["ftr_rac_software"]
                url: racSoftwareUrl,
                alternate: false,
              },
            ]
          : []),
        ...(virtualTourUrl
          ? [
              {
                label: 'ftr_anchor_vtour', // i18n-extract-keys ["ftr_anchor_vtour"]
                url: virtualTourUrl,
                alternate: true,
              },
            ]
          : []),
        ...(immoVisitaUrl
          ? [
              {
                label: 'ftr_remote_visits', // i18n-extract-keys ["ftr_remote_visits"]
                url: immoVisitaUrl,
                alternate: false,
                title: 'ftr_anchor_immovisita', // i18n-extract-keys ["ftr_anchor_immovisita"]
              },
            ]
          : []),
        ...(professionalReportUrl
          ? [
              {
                label: 'ftr_anchor_report', // i18n-extract-keys ["ftr_anchor_report"]
                url: professionalReportUrl,
                alternate: false,
              },
            ]
          : []),
        ...(academyUrl
          ? [
              {
                label: 'Immobiliare.it Academy',
                url: academyUrl,
                alternate: false,
                target: '_blank' as const,
                isClientOnlyLink: true,
              },
            ]
          : []),
      ],
    },
    {
      title: 'ftr_title_real_estate_news', // i18n-extract-keys ["ftr_title_real_estate_news"]
      name: 'news',
      icon: 'newspaper',
      links: [
        ...(newsUrl
          ? [
              {
                label: 'ftr_anchor_blog_immobiliare', // i18n-extract-keys ["ftr_anchor_blog_immobiliare"]
                url: newsUrl,
                alternate: false,
              },
            ]
          : []),
      ],
    },
  ],
];

//configuration for top of footer in compact version (inline link, ex. list)
const segmentsCompact: FooterSegmentCompact[][] = [
  [
    {
      title: getProductConfig('brandName'),
      name: 'info',
      links: [
        ...(whoWeAreUrl
          ? [
              {
                label: 'ftr_anchor_who_we_are', // i18n-extract-keys ["ftr_anchor_who_we_are"]
                url: whoWeAreUrl,
                rel: 'nofollow',
                alternate: true,
              },
            ]
          : []),
        ...(leaderUrl
          ? [
              {
                label: 'ftr_anchor_nr_1', // i18n-extract-keys ["ftr_anchor_nr_1"]
                url: leaderUrl,
                rel: 'nofollow',
                alternate: true,
              },
            ]
          : []),
        ...(pressOfficeUrl
          ? [
              {
                label: 'ftr_anchor_press_office', // i18n-extract-keys ["ftr_anchor_press_office"]
                url: pressOfficeUrl,
                rel: 'nofollow',
                alternate: false,
              },
            ]
          : []),
        ...(workWithUsUrl
          ? [
              {
                label: 'ftr_title_work_with_us', // i18n-extract-keys ["ftr_title_work_with_us"]
                url: workWithUsUrl,
                rel: 'nofollow',
                alternate: false,
              },
            ]
          : []),
        ...(advertisingUrl
          ? [
              {
                label: 'ftr_slim_anchor_advertising', // i18n-extract-keys ["ftr_slim_anchor_advertising"]
                url: advertisingUrl,
                rel: 'nofollow',
                alternate: false,
              },
            ]
          : []),
        ...(contactUsUrl
          ? [
              {
                label: 'ftr_anchor_customer_care_contacts', // i18n-extract-keys ["ftr_anchor_customer_care_contacts"]
                url: contactUsUrl,
                rel: 'nofollow',
                alternate: true,
              },
            ]
          : []),
        ...(newsUrl
          ? [
              {
                label: 'ftr_title_real_estate_news', // i18n-extract-keys ["ftr_title_real_estate_news"]
                url: newsUrl,
                rel: 'nofollow',
                alternate: false,
              },
            ]
          : []),
        {
          label: 'RSS',
          url: '#',
          rel: 'nofollow',
          alternate: false,
        },
      ],
    },
  ],
  [
    {
      title: 'ftr_title_agencies', // i18n-extract-keys ["ftr_title_agencies"]
      name: 'agencies',
      links: [
        ...(publishAdUrl
          ? [
              {
                label: 'ftr_anchor_publish_ad', // i18n-extract-keys ["ftr_anchor_publish_ad"]
                url: publishAdUrl,
                rel: 'nofollow',
                alternate: true,
              },
            ]
          : []),
        ...(networkGroupUrl
          ? [
              {
                label: 'ftr_our_portal', // i18n-extract-keys ["ftr_our_portal"]
                url: networkGroupUrl,
                rel: 'nofollow',
                alternate: true,
              },
            ]
          : []),
        ...(internationalLuxuryUrl
          ? [
              {
                label: 'LuxuryEstate',
                url: internationalLuxuryUrl,
                rel: 'nofollow',
                alternate: true,
                title: 'ftr_luxury_re', // i18n-extract-keys ["ftr_luxury_re"]
              },
            ]
          : []),
        ...(immoSoftwareUrl
          ? [
              {
                label: 'Getrix',
                url: immoSoftwareUrl,
                rel: 'nofollow',
                alternate: true,
                title: 'ftr_anchor_sw_gest', // i18n-extract-keys ["ftr_anchor_sw_gest"]
              },
            ]
          : []),
        ...(virtualTourUrl
          ? [
              {
                label: 'ftr_anchor_vtour', // i18n-extract-keys ["ftr_anchor_vtour"]
                url: virtualTourUrl,
                rel: 'nofollow',
                alternate: true,
              },
            ]
          : []),
        ...(immoVisitaUrl
          ? [
              {
                label: 'ftr_remote_visits', // i18n-extract-keys ["ftr_remote_visits"]
                url: immoVisitaUrl,
                rel: 'nofollow',
                alternate: false,
                title: 'ftr_anchor_immovisita', // i18n-extract-keys ["ftr_anchor_immovisita"]
              },
            ]
          : []),
        ...(insightsUrl
          ? [
              {
                label: 'ftr_anchor_immo_insights', // i18n-extract-keys ["ftr_anchor_immo_insights"]
                url: `${insightsUrl}?from=footer`,
                rel: 'nofollow',
                alternate: false,
              },
            ]
          : []),
      ],
    },
  ],
  [
    {
      title: 'ftr_title_privates', // i18n-extract-keys ["ftr_title_privates"]
      name: 'privates',
      links: [
        ...(userAssistance
          ? [
              {
                label: 'ftr_anchor_faq', // i18n-extract-keys ["ftr_anchor_faq"]
                url: userAssistance,
                rel: 'nofollow noopener',
                alternate: true,
                target: '_blank' as const,
              },
            ]
          : []),
        ...(sellerLeadUrl
          ? [
              {
                label: 'lbl_sell_house', // i18n-extract-keys ["lbl_sell_house"]
                url: `${sellerLeadUrl}?from=footer`,
                rel: 'nofollow',
                alternate: false,
              },
            ]
          : []),
        ...(evaluateBuildingUrl
          ? [
              {
                label: 'ftr_slim_appraise_house', // i18n-extract-keys ["ftr_slim_appraise_house"]
                url: `${evaluateBuildingUrl}?from=footer`,
                rel: 'nofollow',
                alternate: true,
              },
            ]
          : []),
        ...(publishedAdsLandingUrl
          ? [
              {
                label: 'act_publish_a_ad', // i18n-extract-keys ["act_publish_a_ad"]
                url: `${publishedAdsLandingUrl}?from=footer`,
                avoidLocalizedUrl: true,
                rel: 'nofollow',
                alternate: true,
              },
            ]
          : []),
        ...(mortgageUrl
          ? [
              {
                label: 'ftr_slim_mortgages', // i18n-extract-keys ["ftr_slim_mortgages"]
                url: mortgageUrl,
                rel: 'nofollow',
                alternate: false,
              },
            ]
          : []),
        ...(cityGuideUrl
          ? [
              {
                label: 'ftr_anchor_house_prices', // i18n-extract-keys ["ftr_anchor_house_prices"]
                url: cityGuideUrl,
                rel: 'nofollow',
                alternate: true,
                isClientSideNavigation: true,
              },
            ]
          : []),
      ],
    },
  ],
];

export default { segments, segmentsCompact };
